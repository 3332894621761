<template>
  <div>
    <h1>Contact</h1>
    <p>Ha bármi észrevételed, javaslatod, kérdésed van, vagy hibát találtál, akkor írj nekünk egy emailt.</p>
    <a href="mailto:rrd@webmania.cc">
      <font-awesome-icon icon="envelope" /> rrd@webmania.cc
    </a>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
div {
  padding: 1rem;
}
a {
  font-size: 1.75rem;
  text-align: center;
  margin-top: 4rem;
  display: block;
}
</style>
